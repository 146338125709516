<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div>
            <b-form @submit.prevent="onSubmit()">
              <b-row>
                <!-- <b-col col lg="6">
                  <div class="form-group mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Title</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.title"
                      placeholder="Title"
                    />
                    <div class="error mt-1" v-if="submitted && $v.form.title.$error">
                      {{
                      $v.form.title.$errors[0].$message
                      }}
                    </div>
                  </div>
                </b-col>-->

                <b-col col lg="12">
                  <div class="form-group mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                    <!-- <span style="color:red">&nbsp;*</span> -->
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.description"
                      placeholder="Description"
                    />
                    <!-- <div class="error mt-1" v-if="submitted && $v.form.description.$error">
                      {{
                      $v.form.description.$errors[0].$message
                      }}
                    </div>-->
                  </div>
                </b-col>

                <!-- <b-col col lg="6">
                                <div class=" form-group mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label">Choose Image</label>
                                    <input type="file" class="form-control" @change="getImage" />
                                </div>
                </b-col>-->

                <b-col col lg="6">
                  <div class="form-group mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Stake Currency</label>
                    <span style="color:red">&nbsp;*</span>
                    <multiselect
                      v-model="form.stake_currency"
                      :options="crypto"
                      label="currency"
                      track-by="currency"
                      placeholder="Choose..."
                    ></multiselect>

                    <div class="error mt-1" v-if="submitted && $v.form.stake_currency.$error">
                      {{
                      $v.form.stake_currency.$errors[0].$message
                      }}
                    </div>

                    <!-- <div
                      class="error"
                      v-if="submitted && !$v.form.stake_currency.required"
                    >Stake Currency is required</div>-->
                  </div>
                </b-col>

                <b-col col lg="6">
                  <div class="form-group mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Reward Currency</label>
                    <span style="color:red">&nbsp;*</span>
                    <multiselect
                      v-model="form.reward_currency"
                      :options="crypto"
                      label="currency"
                      track-by="currency"
                      value="currency"
                      placeholder="Choose..."
                    ></multiselect>
                    <div class="error mt-1" v-if="submitted && $v.form.reward_currency.$error">
                      {{
                      $v.form.reward_currency.$errors[0].$message
                      }}
                    </div>
                    <!-- <div
                      class="error"
                      v-if="submitted && !$v.form.reward_currency.required"
                    >Reward Currency is required</div>-->
                  </div>
                </b-col>

                <!--Repeat below -->

                <div>
                  <div class="form-box">
                    <div class="mb-2 d-flex align-items-center">
                      <div class="w-50">
                        <label for="text">Stake Plan</label>
                      </div>
                      <!-- Add -->
                      <div class="w-50 text-end">
                        <button
                          title="Add new row"
                          data-toggle="tooltip"
                          data-placement="top"
                          type="button"
                          id="btn"
                          class="btn shadow-none"
                          @click="addRow()"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="var(--navy-blue)"
                              viewBox="0 0 448 512"
                            >
                              <path
                                d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div
                      v-for="(list, index) in stake_plan"
                      :key="index"
                      class="border_box px-3 py-2 mb-3"
                    >
                      <div class="row align-items-center">
                        <div class="col-md-3">
                          <div class="form-group mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Plan Type</label>
                            <span style="color:red">&nbsp;*</span>
                            <multiselect
                              v-model="list.plan_type"
                              :options="plan_type_opt"
                              label="name"
                              track-by="name"
                              placeholder="Choose..."
                              @input="list.plan_type.value == 'fixed' ? (list.showIt = true,list.maturity_days = ''): (list.showIt = false,list.maturity_days = -1)"
                            ></multiselect>
                            <!-- @input="list.plan_type.value == 'flexible' ? list.checkIt = true : list.checkIt = false" -->
                            <!-- @input="assignIt(list.plan_type)" -->
                            <!--list.plan_type.value == 'flexible' ? checkIt = true : checkIt = false-->
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].plan_type"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.form.plan_type.required"
                            >Plan Type is required</div>-->
                          </div>
                        </div>

                        <!-- kjbfhyudsfnds=====  {{list.checkIt}} {{list.plan_type}} -->
                        <div class="col-md-3">
                          <!-- <div v-if="list.checkIt"> -->
                          <!-- <div v-if="list.checkIt">
                            <label for="exampleFormControlTextarea1" class="form-label"> Flexible</label>
                            <input
                              v-model="list.inFlex"
                              @change="list.inFlex ? list.maturity_days = -1 : list.maturity_days = '' "
                              type="checkbox"
                              id="SwitchCheckSizemd"
                              class="ml-4"
                            />
                          </div>-->
                          <div class="form-group mb-3" v-if="list.showIt">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >Maturity Days</label>
                            <span style="color:red">&nbsp;*</span>
                            <!-- <div v-if="list.checkIt" class="flex-end">
                              <span>Flexible</span>
                              <input
                                v-model="list.inFlex"
                                @change="onMaturityDayChange($event,index)"
                                type="checkbox"
                                id="SwitchCheckSizemd"
                                class="ml-4"
                              />
                            </div>
                            <div v-if="!list.inFlex">-->
                            <input
                              type="text"
                              class="form-control"
                              v-model="list.maturity_days"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              placeholder="Maturity Days"
                              onpaste="return false;"
                            />

                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].maturity_days"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- </div> -->

                            <!-- {{$v.stake_plan.$each.$invalid}} -->
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.list.maturity_days.required"
                            >Maturity Days is required</div>
                            <div
                              class="error"
                              v-if="submitted && !$v.list.maturity_days.integer"
                            >Only Numbers Are Allowed</div>-->
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >Min Stake Amount</label>
                            <span style="color:red">&nbsp;*</span>
                            <input
                              type="text"
                              class="form-control"
                              v-model="list.min_stake_amount"
                              @keypress="onlyNumber($event, list.min_stake_amount,8)"
                              placeholder="Minimum Stake Amount"
                              onpaste="return false;"
                            />
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].min_stake_amount"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.form.min_stake_amount.required"
                            >Min Stake Amount is required</div>-->
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >Max Stake Amount</label>
                            <span style="color:red">&nbsp;*</span>
                            <input
                              type="text"
                              class="form-control"
                              v-model="list.max_stake_amount"
                              @keypress="onlyNumber($event, list.max_stake_amount,8)"
                              placeholder="Maximum Stake Amount"
                              onpaste="return false;"
                            />
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].max_stake_amount"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!--

                              @keydown="minStakeCheck(list.min_stake_amount,list.max_stake_amount)"                               <div
                              class="error"
                              v-if="submitted && !$v.form.max_stake_amount.required"
                            >Max Stake Amount is required</div>
                            <div
                              class="error"
                              v-if="submitted && !$v.form.max_stake_amount.minValue"
                            >Max Stake Amount should be more than Min Stake Amount</div>-->
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.form.max_stake_amount.minValue"
                            >Max Stake Amount should be more than Min Stake Amount</div>-->
                            <!-- <div
                              class="error"
                              v-if="submitted && list.min_stake_amount > list.max_stake_amount"
                            >Max Stake Amount should be more than Min Stake Amount</div>-->
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >Plan Start Date</label>
                            <span style="color:red">&nbsp;*</span>
                            <input
                              type="date"
                              class="form-control"
                              v-model="list.plan_start_date"
                              :min="new Date().toISOString().split('T')[0]"
                            />
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].plan_start_date"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- <div class="error" v-if="submitted && !$v.form.plan_start_date.required">
                                        Plan Start Date is required
                            </div>-->
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-md-3">
                          <div class="form-group mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >Plan Expiry Date</label>
                            <span style="color:red">&nbsp;*</span>
                            <!-- <input
                              type="text"
                              class="form-control"
                              v-model="list.plan_expiry_days"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              placeholder="Plan Expiry Days"
                            />-->
                            <input
                              type="date"
                              class="form-control"
                              v-model="list.plan_expiry_days"
                              :min="list.plan_start_date ? add1day(list.plan_start_date) :add1day(new Date().toISOString().split('T')[0])"
                            />
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].plan_expiry_days"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.form.plan_expiry_days.required"
                            >Plan Expiry Days is required</div>
                            <div
                              class="error"
                              v-if="submitted && !$v.form.plan_expiry_days.integer"
                            >Plan Expiry Days Should be integer</div>-->
                          </div>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Total Limit</label>
                            <span style="color:red">&nbsp;*</span>
                            <input
                              type="text"
                              class="form-control"
                              v-model="list.pool_limit"
                              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                              placeholder="Total Limit"
                              onpaste="return false;"
                            />
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].pool_limit"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- <div class="error" v-if="submitted && !$v.form.pool_limit.required">
                                        Pool Limit is required
                            </div>-->
                          </div>
                        </div>

                        <!-- <div class="col-md-2">
                          <div class="form-group mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">ROI Interval</label>
                            <multiselect
                              v-model="list.roi_interval"
                              :options="ROI_opt"
                              label="name"
                              track-by="name"
                              placeholder="Choose..."
                            ></multiselect>
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].roi_interval"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                          </div>
                        </div>-->

                        <div class="col-md-2">
                          <div class="form-group mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >ROI Percentage (%)</label>
                            <span style="color:red">&nbsp;*</span>
                            <input
                              type="text"
                              class="form-control"
                              v-model="list.roi_percentage"
                              @keypress="onlyNumber($event, list.roi_percentage,2)"
                              placeholder="ROI Percentage (%)"
                              onpaste="return false;"
                            />
                            <div
                              class="error"
                              v-if="submitted && $v.stake_plan.$each.$response.$errors"
                            >
                              <div
                                v-for="(error, index) in $v.stake_plan.$each.$response.$errors[index].roi_percentage"
                                :key="index"
                              >{{ error.$message }}</div>
                            </div>
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.form.roi_percentage.required"
                            >ROI Percentage is required</div>-->
                          </div>
                        </div>

                        <div class="col-md-1">
                          <div class="form-group mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >Per User Limit</label>
                            <span style="color:red">&nbsp;*</span>
                            <input
                              type="text"
                              class="form-control"
                              v-model="list.per_user_limit"
                              @keypress="onlyNumber($event, list.per_user_limit,8)"
                              placeholder="Per User Limit"
                              onpaste="return false;"
                            />
                            <div
                              class="error"
                              v-if="submitted && list.per_user_limit==''"
                            >
                              <div
                              >Per User Limit should be greater than Min. Stake Amount.
                              </div>
                            </div>
                            <!-- <div
                              class="error"
                              v-if="submitted && !$v.form.per_user_limit.required"
                            >Per User Limit is required</div>
                            <div
                              class="error"
                              v-if="submitted && !$v.form.per_user_limit.minValue"
                            >Per User Limit should be greater than Min. Stake Amount.</div>-->
                          </div>
                        </div>

                        <div class="col-md-1" v-if="list.showIt">
                          <div class="form-group mb-3">
                            <label class="form-label" for="flexSwitchCheckDefault">Auto Stake Enable</label>

                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              v-model="list.autostake_enable"
                            />

                            <!-- :disabled="!data1.activate_status" -->
                          </div>
                        </div>

                        <div class="col-md-1 text-end" v-if="index > 0 ? true : false">
                          <button
                            title="Delete"
                            data-toggle="tooltip"
                            data-placement="top"
                            type="button"
                            id="btn"
                            class="btn shadow-none"
                            style="cursor: pointer;"
                            @click="deleteRow(index)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="var(--navy-blue)"
                              viewBox="0 0 320 512"
                            >
                              <path
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <!-- Delete -->
                    </div>
                    <!-- border -->
                  </div>
                </div>

                <!---submit button-->
                <div class="col-12 mt-5 text-center">
                  <div class="form-group btn-submit">
                    <div class="spinner-border" role="status" v-if="this.formloading">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      id="submit"
                      v-if="!formloading"
                    >Submit</button>
                  </div>
                </div>
                <!---submit button-->
              </b-row>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ApiClass from "../../api/api";
// import {
//   required,
//   minLength,
//   maxLength,
//   // integer,
//   // minValue,
//   helpers
// } from "vuelidate/lib/validators";
import {
  required,
  // minLength,
  // maxLength,
  helpers,
  // minValue,
  // maxValue,
  // integer,
  requiredIf
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

// required: helpers.withMessage(
//             "Maturity days is required",
//            required
//           ),
export default {
  page: {
    title: "Create"
  },
  setup() {
    return {
      $v: useVuelidate()
    };
  },
  data() {
    return {
      title: "Stake Create",
      items: [
        {
          text: "Staking "
          // href: "/staking/list",
        },
        {
          text: "Staking Create",
          active: true
        }
      ],
      crypto: [],
      plan_type_opt: [
        {
          value: "fixed",
          name: "Fixed"
        },
        {
          value: "flexible",
          name: "Flexible"
        }
      ],
      ROI_opt: [
        {
          value: "D",
          name: "Daily"
        },
        {
          value: "M",
          name: "Monthly"
        },
        {
          value: "Y",
          name: "Year"
        }
      ],
      submitted: false,
      formloading: false,
      min_stake_amount: "",

      form: {
        title: "",
        description: "",
        // image: '',
        stake_currency: "",
        reward_currency: ""
        // plan_type: "",
        // maturity_days: null,
        // roi_percentage: "",
        // per_user_limit: "",
        // roi_interval: "",
        // min_stake_amount: "",
        // max_stake_amount: "",
        // pool_limit: "",
        // plan_expiry_days: "",
        // plan_start_date: ""
      },
      stake_plan: [
        {
          plan_type: "",
          maturity_days: null,
          roi_percentage: "",
          per_user_limit: "",
          // roi_interval: "",
          min_stake_amount: "",
          max_stake_amount: "",
          pool_limit: "",
          plan_expiry_days: "",
          plan_start_date: "",
          checkIt: false,
          inFlex: false,
          showIt: true,
          autostake_enable: 0
        }
      ],
      disable: false
      // checkIt: false,
      // inFlex: false
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  validations() {
    return {
      form: {
        // title: {
        //   required,
        //   minLength: minLength(3),
        //   maxLength: maxLength(200)
        // },
        // description: {
        //   required,
        //   // minLength: minLength(20),
        //   // maxLength: maxLength(500)
        // },
        stake_currency: {
          required
        },
        reward_currency: {
          required
        }
      },
      stake_plan: {
        $each: helpers.forEach({
          plan_type: {
            required: helpers.withMessage("Plan Type is required", required)
          },
          maturity_days: {
            // isValid: (value, user) => {
            //   console.log(value,user)
            //   return false;
            // },

            required: helpers.withMessage(
              "Maturity days is required",
              // requiredIf((value, stake) => {
              //   return !stake.inFlex;
              // })
              requiredIf((value, stake) => {
                return stake.plan_type.value == "fixed";
              })
            )

            //== true ? true : false

            // integer: helpers.withMessage(
            //   "Maturity days should be integers.",
            //   integer
            // ),

            //  isValid: helpers.withMessage(
            //   "Maturity Days should be greater than 0",
            //   (value,stake) => {
            //     console.log((parseFloat(value) > 0 && stake.plan_type.value != "flexible"),parseFloat(value) > 0 ,stake.plan_type.value != "flexible");
            //     return stake.plan_type.value != "flexible" ? (parseFloat(value) > 0):false;
            //   }
            // )
          },
          roi_percentage: {
            required: helpers.withMessage("Roi % is required", required)
          },

          // roi_interval: {
          //   required: helpers.withMessage("Roi interval is required", required)
          // },
          pool_limit: {
            required: helpers.withMessage("Total limit is required", required)
          },
          min_stake_amount: {
            required: helpers.withMessage(
              "Min stake amount is required",
              required
            ),
            isValid: helpers.withMessage(
              "Min stake amount  should be greater than 0",
              value => {
                return parseFloat(value) > 0;
              }
            )
          },

          max_stake_amount: {
            required: helpers.withMessage(
              "Max stake amount is required",
              required
            ),
            isValid: helpers.withMessage(
              "Max Stake Amount  should be greater than min stake amount",
              (value, stake) => {
                return parseFloat(value) >= parseFloat(stake.min_stake_amount);
              }
            )
            // isValid1: helpers.withMessage(
            // "Max stake amount  should be greater than 0",
            // (value) => {
            //   return parseFloat(value) > 0;
            // }
            // )
            //  minValue: helpers.withMessage(
            //   "Max stake amount should be greater than min stake amount",
            // minValue(min_stake_amount))
            // minValue: minValue(function(object) {
            //   return object.min_stake_amount;
            // })
            // minValue: helpers.withMessage(
            //   "Max stake amount should be greater than min stake amount",
            // minValue(min_stake_amount)
            // minValue((object) => { return  object.min_stake_amount})
            // minValue:  minValue(function (object) =>  { return object.min_stake_amount })
            // minValue: function(object) {
            //   return object.min_stake_amount;
            // }
            // )
          },
          per_user_limit: {
            required: helpers.withMessage(
              "Per User Limit is required",
              required
            ),
            //  minValue: minValue(this.stake_plan?.$each.min_stake_amount)
            // minValue(funstion (value,stake){ return stake.min_stake_amount})
            // isValid: helpers.withMessage(
            //   "Per User Limit  should be greater than min stake amount",
            //   minValue((value, stake) =>
            //    {
            //     console.log(value,stake.min_stake_amount);
            //     return stake.min_stake_amount})
            // )
            // minLength: minLength((object) => object.min)
            isValid: helpers.withMessage(
              "Per User Limit  should be greater than max stake amount",
              (value, stake) => {
                return parseFloat(value) >= parseFloat(stake.max_stake_amount);
              }
            ),
            maxValue: helpers.withMessage(
              "Per User Limit  should be less than pool limit",
              (value, stake) => {
                return parseFloat(value) <= parseFloat(stake.pool_limit);
                // return maxValue(stake.pool_limit);
              }
            )
          },
          plan_expiry_days: {
            required: helpers.withMessage(
              "Plan expiry date is required",
              required
            )
          },
          plan_start_date: {
            required: helpers.withMessage(
              "Plan start date is required",
              required
            )
          }
          // order_place_limit: {
          //     required: helpers.withMessage("Order Place Limit is required", required)
          // },
        })
      }
    };
  },
  async mounted() {
    this.getCryptoList();

    console.log({jk: await this.$v.stake_plan})
  },
  methods: {
    add1day(date) {
      console.log(date);
      date = new Date(date);
      let all = new Date(parseInt(date.setDate(date.getDate() + 1)));
      return  JSON.stringify(all).slice(1, 11);
    },
    onMaturityDayChange(event, index) {
      let d = this.stake_plan.filter(el => el.inFlex == true);
      d.length == 1 ? (this.stake_plan[index].maturity_days = -1) : "";

      // list.inFlex ? list.maturity_days = -1 : list.maturity_days = '' "
      if (d.length > 1) {
        event.target.checked = false;
        this.stake_plan[index].inFlex = false;
      }
    },
    // minStakeCheck(min,max){
    //    min = min == '' ? 0 : min;
    //   return (max > min) ? false : true;
    // },
    onlyNumber(event, data, fixed) {
      let value = data.toString();
      if (value.indexOf(".") != -1) {
        var fixedlength = value.split(".")[1];
        if (fixedlength.length == fixed) return event.preventDefault();
      }
      if (event.charCode >= 48 && event.charCode <= 57) {
        return true;
      }
      if (event.charCode == 46) {
        if (value.charAt(0) == "" || value.indexOf(".") != -1)
          return event.preventDefault();
        return true;
      }

      event.preventDefault();
    },
    async getCryptoList() {
      var result = await ApiClass.getRequest("admin/crypto/getlist", true);
      this.crypto = result.data.data.crypto;
      this.cryptoStatus = result.data.data.status;
    },

    async onSubmit() {
      let allmsg = [];
      // console.log(event);
      // event.preventDefault();
      // this.submitted = true;
      // if (this.$v.$invalid) {
      //   return;
      // }
      let msg = "";
      this.submitted = true;
      // const result1 = await this.$v.stake_plan.$validate();
      // console.log({ validation: this.$v, result1 });
      // if (!result1) {
      //   return;
      // }
      console.log(this.stake_plan);
      for (let index = 0; index < this.stake_plan.length; index++) {
        //  await new Promise(resolve => setTimeout(resolve, 1000));
        let x = await this.apiHit(this.stake_plan[index]);
        allmsg.push(x);
      }
      allmsg.forEach((element, index) => {
        msg += "<p>" + (index + 1) + "-" + element + "</p>&nbsp;<br/>";
      });
      // console.log({msg});
      // this.swal("info", msg);
      Swal.fire({
        title: "<i>Responses:</i>",
        html: msg
        // confirmButtonText: "V <u>redu</u>",
      });

      this.ResetForm();
    },
    async apiHit(data) {

      console.log('data--',data);
      this.formloading = true;
      let formdata = {
        title: this.form.title,
        description: this.form.description,
        stake_currency: this.form.stake_currency.currency,
        reward_currency: this.form.reward_currency.currency,

        plan_type: data.plan_type.value,
        maturity_days: data.maturity_days,
        roi_percentage: data.roi_percentage,
        per_user_limit: data.per_user_limit,
        // roi_interval: data.roi_interval.value,
        roi_interval: "D",
        min_stake_amount: data.min_stake_amount,
        max_stake_amount: data.max_stake_amount,
        pool_limit: data.pool_limit,
        plan_expiry_date: data.plan_expiry_days,
        plan_start_date: data.plan_start_date,
        autostake_enable: data.autostake_enable ? 1 : 0
      };
      console.log({ formdata });
      var response = await ApiClass.postNodeRequest(
        "staking/create",
        true,
        formdata
      );
      console.log({ response });
      if (response.data.status_code == 1) {
        this.submitted = false;
        this.formloading = false;
        // this.swal("success", response.data.message);
        // this.ResetForm();
        return response.data.message;
      }
      this.submitted = false;
      this.formloading = false;
      // this.swal("failed", response.data.message);
      return response.data.message;
    },
    // getImage(){
    //     this.form.image=event.target.files[0];

    // },
    ResetForm() {
      (this.form.title = ""),
        (this.form.description = ""),
        // this.form.image = '',
        (this.form.stake_currency = ""),
        (this.form.reward_currency = ""),
        // (this.form.plan_type = ""),
        // (this.form.maturity_days = null),
        // (this.form.roi_percentage = ""),
        // (this.form.per_user_limit = ""),
        // (this.form.roi_interval = ""),
        // (this.form.min_stake_amount = ""),
        // (this.form.max_stake_amount = ""),
        // (this.form.pool_limit = ""),
        // (this.form.plan_expiry_days = ""),
        // (this.form.plan_start_date = "");
        (this.stake_plan = [
          {
            plan_type: "",
            maturity_days: null,
            roi_percentage: "",
            per_user_limit: "",
            // roi_interval: "",
            min_stake_amount: "",
            max_stake_amount: "",
            pool_limit: "",
            plan_expiry_days: "",
            plan_start_date: "",
            checkIt: false,
            inFlex: false,
            autostake_enable: false
          }
        ]);
    },
    addRow() {
      if (this.stake_plan.length == 3) {
        return;
      }
      this.stake_plan.push({
        title: "",
        description: "",
        stake_currency: "",
        reward_currency: "",
        plan_type: "",
        maturity_days: null,
        roi_percentage: "",
        per_user_limit: "",
        roi_interval: "",
        min_stake_amount: "",
        max_stake_amount: "",
        pool_limit: "",
        plan_expiry_days: "",
        plan_start_date: "",
        checkIt: false,
        inFlex: false,
        autostake_enable: false
      });
    },
    deleteRow(index) {
      if (index == 0) {
        return;
      }
      this.stake_plan.splice(index, 1);
    },
    assignIt(list) {
      // console.log({ v });
      return list.plan_type == "flexible"
        ? (list.checkIt = true)
        : (list.checkIt = false);
      // v.value == "flexible" ? (this.checkIt = true) : (this.checkIt = false);
    }
  }
};
</script>

<style scoped>
input.form-control::placeholder {
  color: #adadad;
}

label {
  white-space: nowrap;
}
.border_box {
  border-bottom: 1px solid #ced4da;
}
</style>

